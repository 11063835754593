import React from "react";

export default function CurriculumBtn() {

    return (
        <a 
            href="/download/[BAQUEY] Alternance - CDA.pdf" 
            download 
            className="btn  inner outer border-txt-color-light dark:border-txt-color-dark hover:bg-btn-color-light dark:hover:bg-btn-color-dark hover:border-btn-color-light dark:hover:border-btn-color-dark dark:text-txt-color-dark border-[3px]">
                <strong>C</strong>urriculum
        </a>
    )
}