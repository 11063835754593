import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import crossingTurtle from '../assets/images/bootcamp/crossing_project.png'
import blackJack from '../assets/images/bootcamp/blackjack_project.png'
import guessNumber from '../assets/images/bootcamp/guess_number_project.png'
import hirstPainting from '../assets/images/bootcamp/hirst_painting.png'

import ProjectCard from "./bootcamp/ProjectCard";


export default function Bootcamp() {
    return (
        <section id="bootcamp" className="min-h-[100vh] bg-bg-color-light dark:bg-bg-color-dark pt-20 pb-12 px-4 sm:px-12 lg:px-28">
            <div className=" w-full flex items-start lg:items-end flex-col ">
                <div className="w-full sm:w-7/12 lg:w-[30%]">
                    <h1 className="text-[40px] lg:text-7xl text-title-color-light dark:text-title-color-dark">Bootcamp</h1>
                    <h2 className="text-[35px] lg:text-6xl lg:text-right text-title-color-light dark:text-title-color-dark">Python<FontAwesomeIcon icon="fa-brands fa-python" className=" text-5xl text-accent-color-light dark:text-accent-color-dark ml-3" /></h2>
                </div>
            </div>

            <div className="lg:w-8/12 mt-18 text-justify text-txt-color-light dark:text-txt-color-dark">
                <p>Bienvenue dans la section dédiée à mon parcours au <strong>Bootcamp Pro Python</strong>, lancé fin 2023. <br /> Suivez mes projets en temps réel sur <a href="https://twitter.com/Tyujhty92" target="__blank" className="text-accent-color-light dark:text-accent-color-dark font-bold">Twitter</a>, et explorez mon <a href="https://github.com/Tyujhty" target="__blank" className="text-accent-color-light dark:text-accent-color-dark font-bold">GitHub</a> pour découvrir mes réalisations. <br /><br /> Un aperçu direct de ma progression dans le monde du développement. 🚀</p>
            </div>

            <div className="text-center mt-16 flex flex-wrap justify-center">
                <ProjectCard 
                    title="BlackJack Game" 
                    number={11}
                    link="https://github.com/Tyujhty/Day-11---Blackjack"
                    img={blackJack}
                    textColorProp="text-title-color-light" 
                />
                <ProjectCard 
                    title="Guess the number" 
                    number={12}
                    link="https://github.com/Tyujhty/Day-12---Guess-number"
                    img={guessNumber}
                    textColorProp="text-title-color-light" 
                />
                <ProjectCard 
                    title="Hirst Painting" 
                    number={18}
                    link="https://github.com/Tyujhty/Day-18---Hirst-painting"
                    img={hirstPainting}
                    textColorProp="" 
                />
                <ProjectCard 
                    title="Turtle Crossing game" 
                    number={22}
                    link="https://github.com/Tyujhty/Day-21---Pong-game"
                    img={crossingTurtle}
                    textColorProp="" 
                />
            </div>

        </section>
    )
}