import React from "react";

export default function NavbarLink({ href, text, isActive, onClick }) {
  const dark = true;
  const handleClick = () => {
    onClick(href);
  };

  return (
    <li className="group py-6 md:py-0">
      <a
        className={`text-2xl md:text-xl duration-200 group-hover:cursor-pointer group-hover:text-accent-color-light dark:group-hover:text-accent-color-dark ${isActive ? "text-accent-color-light" : "text-txt-color-light"} ${dark ? (isActive ? "dark:text-accent-color-dark" : "dark:text-txt-color-dark") : ""}`}
        href={href}
        onClick={handleClick}
      >
        {text}
      </a>
    </li>
  );
}
