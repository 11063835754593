import React from "react";

export default function StudyCtn({ title, level, year, location }) {
  return (
    <div className="py-2 px-4 rounded w-full mb-4 mx-auto bg-bg-color-light-sub dark:bg-bg-color-dark-sub">
        <h3 className="text-2xl text-accent-color-light dark:text-accent-color-dark">{title}</h3>
        <p className="text-base text-txt-color-light dark:text-txt-color-dark">{level}</p>
        <div className="flex justify-between mt-4 text-accent-color-light dark:text-accent-color-dark">
          <p>{year}</p>
          <p>{location}</p>
        </div>
    </div>
  );
}
