import React from "react";

import Testimonial from "./components/Testimonial";
import Skills from "./components/Skills";
import Project from "./components/Projects";
import Contact from "./components/Contact";
import Navbar from "./components/navbar/Navbar";
import UpBtn from "./components/buttons/Up.button";
import Bootcamp from "./components/Bootcamp";

import "./App.scss";

//FontAwesome icons import
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

//TailwindsCss elements import


library.add(fab, fas);

export default function App() {

  document.title = 'Fanny Baquey - Développeuse Web et Web Mobile'
  
  return (
    <div>
      <Navbar />
      <Testimonial />
      <Skills />
      <Bootcamp />
      <Project />
      <Contact />
      <UpBtn />
    </div>
  )
}