import React, {useState} from "react";

export default function SwitchBtn( {isToggleMenu} ) {

    const [isDarkMode, setIsDarkMode] = useState(false);

    const handleClick = () => {
        setIsDarkMode(!isDarkMode);
        document.documentElement.classList.toggle("dark");
    }

    return (
        <div className={`fixed md:block ${isToggleMenu ? "block" : "hidden"} top-48 md:top-auto md:-bottom-14 z-20 md:z-0  -left-1 darkModeBtn`}>
            <p className="vertical-text relative left-4 bottom-20 text-txt-color-light dark:text-txt-color-dark">AMBIANCE</p>
            <label className="switch border-[4px] dark:border-accent-color-dark">
                <input type="checkbox" className="switch-frame" onClick={handleClick} />
                <span className="slider round bg-bg-color-light "></span>
            </label>
        </div>
    )
}