import React from "react";  


export default function SkillBar({width, color, title, text1, text2}) {
    return (
        <div className="my-8">  
            <div className="flex items-center mb-[1rem]">
                <h3 className="text-2xl text-accent-color-light dark:text-accent-color-dark mr-6"><strong>{title}</strong></h3>
                <div className="w-3/5 h-2 bg-slate-50 my-3 rounded-full">
                    <div className={`${width} ${color} h-2`}></div>
                </div>
            </div>
            <p className="text-txt-color-light dark:text-txt-color-dark mb-2">. <strong>Compétences : </strong> {text1}</p>
            <p className="text-txt-color-light dark:text-txt-color-dark mb-2">. <strong>Objectifs : </strong> {text2}</p>
        </div>
    );
}