import React from "react"

import SkillIcon from "./testimonial/SkillIcon";

export default function Testimonial() {

    const skillIcons = [
        { icon: "fa-brands fa-html5"},
        { icon: "fa-brands fa-sass"},
        { icon: "fa-brands fa-square-js"},
        { icon: "fa-brands fa-symfony"},
        { icon: "fa-brands fa-python"},
    ];

    return (
        <section id="testimonial" className="min-h-[100vh] bg-bg-color-light dark:bg-bg-color-dark pt-6 md:pt-14 flex px-4 sm:px-12 lg:px-  xl:px-20">
            <div className="flex flex-col lg:flex-row w-full">
                <div className="flex justify-center items-center flex-col flex-auto h-full w-full lg:w-6/12 pb-[5rem] lg:p-0">

                    <div className="flex flex-col mb-8">
                        <h1 className="text-[65px] md:text-8xl relative w-full flex justify-end md:-left-28 -bottom-9 md:-bottom-8 text-title-color-light dark:text-title-color-dark">BAQUEY</h1>
                        <h2 translate="no" className="text-[50px] w-full flex justify-end text-title-color-light dark:text-title-color-dark">Fanny</h2>
                    </div>
                    <div className=" text-right">
                        <p className="text-txt-color-light dark:text-txt-color-dark text-xl"><strong className=" text-accent-color-light dark:text-accent-color-dark ">D</strong>écouvrir mon projet d'étude</p>
                        <a href="https://progica.fannybaquey.com/" target="_notblank" className="btn border-btn-color-light dark:border-btn-color-dark border-[3px] bg-btn-color-light dark:bg-btn-color-dark hover:border-txt-color-light dark:hover:border-txt-color-dark dark:hover:bg-transparent dark:text-txt-color-dark hover:border-[3px] hover:bg-transparent outer inner relative -left-10 sm:left-20 mt-4 top-10"><strong>P</strong>rojet <strong>P</strong>rogica</a>
                    </div>
                </div>

                <div className="flex flex-col justify-center w-full lg:w-6/12 px-2 lg:pr-8 flex-auto">
                    <div className="text-center lg:text-right">
                        <h3 className="text-[24px] md:text-[34px] text-title-color-light dark:text-title-color-dark"><strong className=" text-accent-color-light dark:text-accent-color-dark ">D</strong>éveloppeuse <strong className=" text-accent-color-light dark:text-accent-color-dark ">Web</strong> et <strong className=" text-accent-color-light dark:text-accent-color-dark ">Web mobile</strong></h3>
                        <p className=" text-[18px] md:text-3xl text-txt-color-light dark:text-title-color-dark">en <strong className=" text-accent-color-light dark:text-accent-color-dark ">R</strong>echerche d'une alternance</p>
                    </div>
                    <div className="text-center lg:text-right my-8">
                        <div>
                            {skillIcons.map((icon)=> (
                                <SkillIcon icon={icon.icon} />
                            ))}

                        </div>
                    </div>
                    <div className="">
                        <p className="text-justify text-txt-color-light dark:text-txt-color-dark">
                            Je m'appelle <span translate="no">Fanny</span> Baquey, une étudiante en développement web. En octobre 2023, j'ai <strong className=" text-accent-color-light dark:text-accent-color-dark ">validé ma certification de niveau 5</strong> après avoir suivi une formation intensive de 9 mois à l'Afpa. <br /><br />

                            Actuellement, je participe à <strong className=" text-accent-color-light dark:text-accent-color-dark ">un bootcamp pour approfondir mes compétences en Python</strong>, car je souhaite intégrer le Machine Learning et le NLP dans mes futurs projets.<br /><br />

                            Mes compétences en développement front-end incluent <strong className=" text-accent-color-light dark:text-accent-color-dark ">HTML, Sass, Tailwind</strong> et des <strong className=" text-accent-color-light dark:text-accent-color-dark ">bases en React</strong> et <strong className=" text-accent-color-light dark:text-accent-color-dark ">JavaScript</strong> pour créer des interfaces utilisateur à la fois responsives et accessibles. <br /><br />

                            Côté back-end, j'ai travaillé avec <strong className=" text-accent-color-light dark:text-accent-color-dark ">le framework Symfony</strong> et j'ai <strong className=" text-accent-color-light dark:text-accent-color-dark ">une bonne compréhension de MySQL</strong> pour la gestion des données. <br /><br />

                            Basée dans le <strong className=" text-accent-color-light dark:text-accent-color-dark ">Vaucluse</strong>, je suis actuellement à la <strong className=" text-accent-color-light dark:text-accent-color-dark ">recherche d'une alternance</strong> ou <strong className=" text-accent-color-light dark:text-accent-color-dark ">de projets dans le domaine du développement (web, application ou IA) </strong>. N'hésitez pas à me contacter pour discuter d'opportunités intéressantes. <br /><br />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}