import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Contact(){
    return (
        <section id="contact" className="min-h-[100vh] bg-bg-color-light dark:bg-bg-color-dark pt-20 pb-12 px-4 sm:px-12 lg:px-28">
            <div className=" w-full flex items-start lg:items-end flex-col ">
                <div className="w-full sm:w-7/12 lg:w-5/12">
                    <h1 className="text-[40px] lg:text-7xl text-title-color-light dark:text-title-color-dark">Contact &</h1>
                    <h2 className="text-[35px] lg:text-6xl lg:text-right text-title-color-light dark:text-title-color-dark">informations</h2>
                </div>
                <p className="font-semibold text-xl text-txt-color-light dark:text-txt-color-dark"><FontAwesomeIcon icon="fa-solid fa-circle" className="text-green-500 dark:text-accent-color-dark mt-4 mr-4" /> Je suis disponible</p>
            </div>

            <div className="lg:w-8/12 mt-24 text-justify text-txt-color-light dark:text-txt-color-dark">
                <p>Je suis à la recherche d'<strong className="text-accent-color-light dark:text-accent-color-dark"> une d'alternance en développement </strong> pour 2024. Mon objectif est d'approfondir mes compétences dans les technologies telles que Symfony, Python, React, etc. <br /> <br />
                
                Je suis convaincue que l'alternance est une occasion <strong className="text-accent-color-light dark:text-accent-color-dark">d'allier théorie et pratique</strong>, et de développer mes compétences tout en contribuant activement à des projets concrets. Je suis prête à <strong className="text-accent-color-light dark:text-accent-color-dark">m'investir pleinement dans une entreprise </strong> qui me permettra de mettre en valeur mes connaissances et de continuer à progresser dans ma carrière de développeuse. <br /><br />
                
                N'hésitez pas à <strong className="text-accent-color-light dark:text-accent-color-dark">me contacter</strong> si vous avez des opportunités intéressantes à discuter.
                <br /><br />
                <strong className="text-accent-color-light dark:text-accent-color-dark">à bientôt</strong>
                </p>
            </div>
            <div className="text-center mt-6 sm:mt-20 xl:mt-[6rem]">
                <p className="hidden sm:block text-txt-color-light dark:text-txt-color-dark mb-6"><strong className="text-accent-color-light dark:text-accent-color-dark">N'hésitez pas</strong> à m'envoyer un message</p>
                <a href="mailto:contact@fannybaquey.com" className="btn border-btn-color-light dark:border-btn-color-dark border-[3px] bg-btn-color-light dark:bg-btn-color-dark hover:border-txt-color-light dark:hover:border-txt-color-dark dark:hover:bg-transparent dark:text-txt-color-dark hover:border-[3px] hover:bg-transparent outer inner relative  sm:left-24"><strong>M</strong>e <strong>c</strong>ontacter</a>
            </div>

        </section>
    )
}