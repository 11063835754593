import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UpBtn() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="fixed bottom-2 right-4 text-5xl text-txt-color-light dark:text-txt-color-dark z-30"
      onClick={handleScrollToTop}
    >
      <FontAwesomeIcon
        icon="fa-solid fa-circle-up"
        className="hover:text-accent-color-light dark:hover:text-accent-color-dark duration-300 hover:cursor-pointer"
      />
    </div>
  );
}
