import progica from "../assets/images/progica-home.png"
import pacteCivique  from '../assets/images/pactecivique-home.png'

import ProjectCtn from "./projects/ProjectCtn";

export default function Project(){
    const projectElements = [
        {desc: "Projet de stage sous Symfony incluant un scraping et une analyse NLP en Python, avec gestion d'archive, d'options et d'utilisateurs.", title:"Archivage : Pacte civique", year:"2023", skills:["Synfony", "MySQL", "Tailwind CSS", "Python"], icons:[{ icon:'fa-brands fa-github', link:"https://github.com/Tyujhty/projet-archivage"}], imgs:[{ img:pacteCivique}]},
        {desc: "Projet d'étude réalisé en utilisant le framework Symfony. Les utilisateurs peuvent créer un compte, effectuer des recherches multicritères et les résultats sont affichés en temps réel grâce à l'utilisation de la technologie Ajax.", title:"Progica", year:"2023", skills:["Synfony", "MySQL", "Sass"], icons:[{ icon:'fa-brands fa-github', link:"https://github.com/Tyujhty/progica"}], imgs:[{ img:progica, link: "https://progica.fannybaquey.com" }]},
    ]

    return (
        <section className="min-h-[100vh] bg-bg-color-light dark:bg-bg-color-dark pt-8 md:pt-14 flex flex-col px-4 sm:px-12 lg:px-28" id="projects">
            <div className="sm:w-7/12 lg:w-4/12 pt-8">
                <h1 className="text-[40px] lg:text-7xl text-title-color-light dark:text-title-color-dark">Projets &</h1>
                <h2 className="text-[35px] lg:text-6xl lg:text-right text-title-color-light dark:text-title-color-dark">expériences</h2>
            </div>
            {projectElements.map((element)=>(
                <ProjectCtn 
                    desc={element.desc} 
                    title={element.title} 
                    year={element.year} 
                    skills={element.skills} 
                    icons={element.icons} 
                    imgs={element.imgs}
                />
            ))}
        </section>
    )
}
