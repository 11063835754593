import SkillBar from "./Skills/SkillBar";
import StudyCtn from "./Skills/StudyCtn";

export default function Skills(){

    const skillGeneralBars= [
        {with: "w-[50%]", color: "bg-sky-800", title: "Front-end", text1: "HTML, CSS (Tailwind CSS, Sass) et javaScript.", text2: "Maîtriser React et explorer Vue.js."},
        {with: "w-[40%]", color: "bg-sky-800", title: "Back-end", text1: "Symfony, MySQL.", text2: "Améliorer mes compétences en création et automatisation des bases de données."}
    ]
    const skillSpecificBars= [
        {with: "w-[50%]", color: "bg-sky-500", title: "Symfony", text1: "Structure MVC, gestion des utilisateurs, gestion de la sécurité, code propre.", text2: "Intégration de React et Vue.js."},
        {with: "w-[40%]", color: "bg-sky-500", title: "Python", text1: "Syntaxe Python, structures de contrôle, fonctions, types de données, bibliothèques courantes.", text2: "Renforcement algorithmique, expertise bibliothèques NLP, apprentissage en machine learning"}
    ]

    return (
        <section className="min-h-[100vh] bg-bg-color-light dark:bg-bg-color-dark pt-6 md:pt-14 flex items-center" id="skills">
            <div className="flex flex-col-reverse lg:flex-row px-4 sm:px-12 lg:px-28">
                <div className="flex-1 lg:pr-40">
                    <div>
                        <h3 className="text-3xl pt-10 text-title-color-light dark:text-title-color-dark"><strong className="text-accent-color-light dark:text-accent-color-dark">01.</strong> Compétences Générales
                        </h3>
                        <div className="w-full">
                            {skillGeneralBars.map((bar)=>(
                                <SkillBar 
                                width={bar.with} 
                                color={bar.color} 
                                title={bar.title}
                                text1={bar.text1}
                                text2={bar.text2}
                                />
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-3xl pt-4 text-title-color-light dark:text-title-color-dark">
                        <strong className="text-accent-color-light dark:text-accent-color-dark">02.</strong> Compétences Spécifiques
                        </h3>
                        <div className="w-full">
                            {skillSpecificBars.map((bar)=>(
                                    <SkillBar 
                                    width={bar.with} 
                                    color={bar.color} 
                                    title={bar.title}
                                    text1={bar.text1}
                                    text2={bar.text2}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
                <div className="flex-1 mt-[3rem] skills-txt">
                    <div className="skills-txt-titles">
                        <h1 className="text-[40px] lg:text-7xl text-title-color-light dark:text-title-color-dark">Compétences &</h1>
                        <h2 className="lg:text-right text-[35px] lg:text-6xl text-txt-color-light dark:text-title-color-dark">formations</h2>
                    </div>
                    <div className="my-20 text-justify text-txt-color-light dark:text-txt-color-dark">
                        <p>Je relève le <strong className="text-accent-color-light dark:text-accent-color-dark">défi #100DaysOfCoding</strong> en Python, suivez ma progression sur <a href="https://twitter.com/Tyujhty92" target="__blank" className="text-accent-color-light dark:text-accent-color-dark">Twitter</a>.<br /> <br /> Mon <strong className="text-accent-color-light dark:text-accent-color-dark">niveau d'anglais est évalué à C1</strong> , ce qui me permet de communiquer dans un contexte professionnel.</p>
                    </div>
                    <StudyCtn title="Titre professionnel Developpement Web et Web Mobile" level="Niveau 5" year="2022 - 2023" location="Afpa - Territoire Digital" />
                    <StudyCtn title="Titre professionnel Responsable d'espace de médiation numérique" level="Niveau 5" year="2019 - 2020" location="Afpa - Le Pontet" />
                </div>
            </div>
        </section>
    )
}