import React, {useState, useRef, useEffect} from "react";

import NavbarLink from "./NavbarLink";
import CurriculumBtn from "../buttons/Curriculum.button";
import SocialLink from "./SocialLink";
import SwitchBtn from "../buttons/Switch.button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Navbar() {
    
    //Fermeture du menu responsive
    const menuContainerRef = useRef(null);
    const socialContainerRef = useRef(null);
    const darkModeBtnRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
          if (
                !(            
                    menuContainerRef.current?.contains(event.target) ||
                    socialContainerRef.current?.contains(event.target) ||
                    darkModeBtnRef.current?.contains(event.target)
                )
          ) {
            setIsToggleMenu(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);


    const [activeLink, setActiveLink] = useState(null);
    const [isToggleMenu, setIsToggleMenu] = useState(false);

    function handleLinkClick(link) {
        setActiveLink(link);
    }

    function handleClick() {
        setIsToggleMenu(!isToggleMenu);
    } 

    const navLinks = [
        { href: "#testimonial", text: "Accueil" },
        { href: "#skills", text: "Compétences" },
        { href: "#bootcamp", text: "Bootcamp Pyhton" },
        { href: "#projects", text: "Projets" },
        { href: "#contact", text: "Contact" },
      ];

      

    const socialLinks = [
        { href:"https://github.com/Tyujhty", icon:"fa-brands fa-github" },
        { href:"https://www.linkedin.com/in/fanny-baquey/", icon:"fa-brands fa-linkedin" },
        { href:"https://twitter.com/Tyujhty92", icon:"fa-brands fa-twitter" },
        //{ href:"https://www.freecodecamp.org/tuyjhty", icon:"fa-brands fa-free-code-camp" },
        { href:"mailto:contact@fannybaquey.com", icon:"fa-solid fa-envelope" },
    ]

    return (
            <>
                <nav className="z-20 h-nav-height w-full fixed flex bg-bg-color-light dark:bg-bg-color-dark sm:bg-transparent sm:dark:bg-transparent sm:backdrop-blur-md">

                    <ul 
                        ref={menuContainerRef}
                        className={`md:h-nav-height md:w-2/4 ${ isToggleMenu ? "flex" : "hidden"} md:flex flex-col md:flex-row justify-center md:justify-around items-center list-none absolute md:relative top-nav-height md:top-0 w-full min-h-[40vh] md:min-h-[auto] px-2 bg-nav-responsive-light dark:bg-nav-responsive-dark md:dark:bg-transparent md:bg-transparent`} 
                        id="navbar-ctn">
                    
                            {navLinks.map((link) => (
                                <NavbarLink
                                    key={link.href}
                                    href={link.href}
                                    text={link.text}
                                    isActive={activeLink === link.href}
                                    onClick={() => handleLinkClick(link.href)}
                                />
                            ))}
                    </ul>
                    <div className="h-nav-height relative w-2/4 flex justify-center items-center">
                        <CurriculumBtn />
                    </div>
                    <div 
                        className="md:hidden flex justify-center items-center text-5xl text-txt-color-light dark:text-txt-color-dark hover:text-accent-color-light dark:hover:text-accent-color-dark duration-200 hover:cursor-pointer relative -right-28 btnToggle" 
                        onClick={handleClick}>
                        <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                    </div>
                    <div>
                        <hr className="hidden md:block absolute right-6 md:right-[2.1rem] top-0 h-nav-height border-[2px] border-txt-color-light dark:border-txt-color-dark" />
                    </div>
                    <div 
                        ref={socialContainerRef}
                        id="navbar-social" 
                        className={`  ${isToggleMenu ? 'block' : 'hidden'} md:block`}>
                        
                        <hr className="absolute right-6 md:right-[2.1rem] h-28 md:h-10 lg:h-40 top-nav-height border-[2px] border-txt-color-light dark:border-txt-color-dark rounded-b" />
                        
                        <div className="h-40 absolute right-0 md:right-[0.5rem] top-48 md:top-28 lg:top-60 w-14 flex flex-col justify-around items-center">
                            
                            {socialLinks.map((socialLink) => (
                                <SocialLink
                                    href={socialLink.href}
                                    icon={socialLink.icon}
                                />
                            ))}
                        
                    </div>
                </div>
            </nav>
        
            <div ref={darkModeBtnRef}>
                <SwitchBtn isToggleMenu={isToggleMenu} />
            </div>
        </>
    )
}