import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProjectCtn({ desc, title, year, skills, icons, imgs }) {
  return (
    <div className=" lg:w-11/12 flex flex-col justify-center items-center lg:flex-row mt-24 lg:mt-44 xl:mt-60 2xl:mb-96 relative">
      <div className="w-full sm:w-8/12 lg:w-6/12 z-10 flex lg:block flex-col lg:relative right-44">
        <div className="flex flex-col w-full lg:w-[40%] mb-4 py-2 px-4 rounded-md">
            <h2 className="text-3xl font-semibold text-accent-color-light dark:text-accent-color-dark">{title}</h2>
            <p className="text-xl font-semibold text-txt-color-light dark:text-txt-color-dark mt-4">{year}</p>
          </div>
        <p className="mt-4 lg:mt-0 lg:w-[70%] bg-bg-color-light-sub dark:bg-bg-color-dark-sub text-txt-color-light dark:text-txt-color-dark px-3 py-4 rounded relative">{desc}</p>

        <div className="mt-4 w-full lg:w-7/12">
          <div className="w-full lg:w-8/12">
            <ul className="flex flex-wrap lg:flex-nowrap w-full lg:w-7/12 text-txt-color-dark dark:text-txt-color-light mt-2">
              {skills.map((skill, index) => (
                <li key={index} className=" dark:bg-txt-color-dark bg-accent-color-light font-semibold py-1 px-2 rounded mr-4 mt-2 min-w-[100px] text-center">{skill}</li>
              ))}
            </ul>
            <div className="mt-6 flex items-center">
              {icons.map((icon, index) => (
                <a href={icon.link} key={index} target="__blanck">
                  <FontAwesomeIcon icon={icon.icon} className="mr-4 text-3xl text-txt-color-light dark:text-txt-color-dark hover:text-accent-color-light dark:hover:text-accent-color-dark hover:cursor-pointer duration-300"/>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:absolute md:w-[90%] lg:w-[70%] -right-20 -top-46 lg:grayscale hover:grayscale-0 duration-300 mt-4 ">
        {imgs.map((img, index)=> (
          <a href={img.link} key={index} target="_blank">
            <img src={img.img} alt="illustration" className="hover:cursor-pointer rounded-xl" />
          </a>
        ))}
      </div>
    </div>
  );
}
