import React from "react";

export default function ProjectCard({ title, number, link, img, textColorProp }) {
  const backgroundImageStyle = {
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
  };

  return (
    <a
      href={link}
      target="__blank"
      className={`w-[250px] h-[250px] flex flex-col justify-center rounded-md mx-4 mb-6`}
      style={backgroundImageStyle}
    >
      <div className="transition backdrop-blur-md hover:backdrop-blur-none p-2 rounded-md w-full h-full">
        <div className={`w-full h-full flex justify-center items-center flex-col transition opacity-100 hover:opacity-0 ${textColorProp || "text-title-color-dark"}`}>
          <h3 className="text-3xl mb-4">{title}</h3>
          <p className="text-2xl">Projet n°{number}</p>
        </div>
      </div>
    </a>
  );
}
